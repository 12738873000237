<template>
    <v-container id="container" class="d-flex justify-center align-center">
        <v-overlay :dark="false">
            <v-card min-width="250" max-width="350" tile>
                <v-card-text>
                    <v-row id="main-row" justify="center" align="center">
                        <v-col xs="12">
                            <v-card flat :disabled="isDisabled">
                                <v-card-title
                                    class="flex-column justify-center"
                                >
                                    <div class="d-flex align-center">
                                        <Rhythm
                                            height="48px"
                                            width="48px"
                                            class="ma-2"
                                        />
                                        <div
                                            class="text-h4"
                                            @click.capture="
                                                showUsernameAndPassword = !showUsernameAndPassword
                                            "
                                        >
                                            Rhythm
                                        </div>
                                    </div>
                                    <div class="text-subtitle-1">
                                        Log in to your account
                                    </div>
                                </v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-btn
                                                @click="signInWithGoogle"
                                                elevation="0"
                                                block
                                                large
                                                tile
                                                tabindex="1"
                                                class="my-2"
                                            >
                                                <Google
                                                    height="20px"
                                                    width="20px"
                                                    class="ma-2"
                                                />

                                                Google
                                            </v-btn>
                                            <v-btn
                                                @click="signInWithMicrosoft"
                                                elevation="0"
                                                block
                                                large
                                                tile
                                                tabindex="2"
                                                class="my-2"
                                            >
                                                <Microsoft
                                                    height="20px"
                                                    width="20px"
                                                    class="ma-2"
                                                />
                                                Microsoft
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="showUsernameAndPassword">
                                        <v-col cols="12">
                                            <div
                                                class="d-flex align-center justify-center"
                                            >
                                                <span class="text-overline"
                                                    >Or</span
                                                >
                                            </div>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-form ref="form">
                                                <v-row align="center">
                                                    <v-col>
                                                        <v-text-field
                                                            v-model="email"
                                                            :error-messages="
                                                                emailErrors
                                                            "
                                                            label="Email"
                                                            required
                                                            @input="
                                                                $v.email.$touch()
                                                            "
                                                            @blur="
                                                                $v.email.$touch()
                                                            "
                                                            prepend-icon="mdi-mail"
                                                            tabindex="2"
                                                            dense
                                                        ></v-text-field>
                                                        <v-text-field
                                                            v-model="password"
                                                            :type="
                                                                showPassword
                                                                    ? 'text'
                                                                    : 'password'
                                                            "
                                                            :error-messages="
                                                                passwordErrors
                                                            "
                                                            label="Password"
                                                            required
                                                            @input="
                                                                $v.password.$touch()
                                                            "
                                                            @blur="
                                                                $v.password.$touch()
                                                            "
                                                            prepend-icon="mdi-lock"
                                                            :append-icon="
                                                                showPassword
                                                                    ? 'mdi-eye'
                                                                    : 'mdi-eye-off'
                                                            "
                                                            @click:append="
                                                                showPassword = !showPassword
                                                            "
                                                            tabindex="3"
                                                            dense
                                                        />
                                                        <v-btn
                                                            @click="signIn"
                                                            elevation="0"
                                                            tile
                                                            block
                                                            tabindex="4"
                                                            class="mt-4 d-block"
                                                            >Sign In</v-btn
                                                        >
                                                    </v-col>
                                                </v-row>
                                            </v-form>
                                        </v-col>
                                    </v-row>
                                    <v-alert
                                        v-if="authError"
                                        dismissible
                                        type="error"
                                    >
                                        {{ authError }}
                                    </v-alert>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-overlay>
    </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, email } from 'vuelidate/lib/validators'

export default {
    components: {
        Rhythm: () => import('@/components/assets/icons/Rhythm'),
        Google: () => import('@/components/assets/icons/Google'),
        Microsoft: () => import('@/components/assets/icons/Microsoft'),
    },
    mixins: [validationMixin],
    validations: {
        email: { required, email },
        password: { required, minLength: minLength(6) },
    },
    data: () => ({
        email: '',
        password: '',
        confirmPassword: '',
        showPassword: false,
        showUsernameAndPassword: false,
    }),
    methods: {
        async signIn() {
            const { email, password } = this
            try {
                this.$store.dispatch('auth/signIn', {
                    email,
                    password,
                })
            } catch (error) {
                console.error(error)
            }
        },
        async signInWithGoogle() {
            try {
                this.$store.dispatch('auth/signInWithGoogle')
            } catch (error) {
                console.error(error)
            }
        },
        async signInWithMicrosoft() {
            try {
                this.$store.dispatch('auth/signInWithMicrosoft')
            } catch (error) {
                console.error(error)
            }
        },
    },
    computed: {
        isDisabled() {
            return (
                this.$store.getters['auth/isAuthenticated'] ||
                this.$store.getters['auth/isBusy']
            )
        },
        authError() {
            return this.$store.getters['auth/getError']
        },
        emailErrors() {
            const errors = []
            if (!this.$v.email.$dirty) return errors
            !this.$v.email.email && errors.push('Must be valid e-mail')
            !this.$v.email.required && errors.push('E-mail is required')
            return errors
        },
        passwordErrors() {
            const errors = []
            if (!this.$v.password.$dirty) return errors
            !this.$v.password.minLength &&
                errors.push('Password must be at least 6 characters long')
            !this.$v.password.required && errors.push('Password is required')
            return errors
        },
    },
}
</script>

<style scoped>
#container {
    height: 100%;
}
</style>
